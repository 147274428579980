import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { debounce, sortBy } from 'lodash-es';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { keyedPatientAttachmentStatusOptions } from '../../../core/options/patient-attachment-status.opts';
import { NextResult } from '../../../core/models/paged-results/cursor-based-paging.model';
import { ConsultNoteQueueService } from '../../../core/services/consult-note-queue.service';
import { AppToastService } from 'app/core/services/app-toast.service';
import { isConsultNoteSubmittable } from '../../../core/lib/verification-utils';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { SubmitConsultNoteResult } from 'app/core/models/submit-consult-note-result.model';
import { PatientAttachmentHistoryItem } from 'app/core/models/patient-attachment-history-item.model';
import { keyedReasonOptions } from 'app/core/options/capture-status-reason.opts';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import { camelToSnakeCase } from 'app/core/lib/string-utils';
import { displayCaptureStatus } from 'app/core/options/capture-status.opts';

const ScrollOffsetThreshold = 10;

@Component({
  selector: 'app-consult-note',
  templateUrl: './consult-note.component.html',
  styleUrls: ['./consult-note.component.scss'],
})
export class ConsultNoteComponent implements OnInit, OnDestroy {
  @ViewChild('consultNoteSubmitResult') private consultNoteSubmitResultRef: TemplateRef<any>;
  @ViewChild('consultNoteProcessing') private consultNoteProcessingRef: TemplateRef<any>;

  patientAttachmentId: string;
  patientAttachment: PatientAttachment;

  refreshing = true;
  submitEnabled = true;
  currentSection = 'validateConsultNote';
  canAdmin = false;

  debouncedWindowScroll = debounce(
    this.handleWindowScroll,
    250,
    { maxWait: 1000 }
  );

  patientAttachmentChangedSubscription = null;

  // Attributes related to the submit consult note result flash
  // message. Ideally, it would be nice to pass an Angular component
  // to the toastService but that is more work than it's worth
  // for right now.
  submitConsultNoteResult: SubmitConsultNoteResult;
  verifiedCount = 0;

  rolledUpTransitionedCaptureStatusCounts = [];
  rolledUpFailedTransitionedCaptureStatusCounts = [];

  constructor(
    private route: ActivatedRoute,
    private patientAttachmentsService: PatientAttachmentsService,
    private navigationService: NavigationService,
    private consultNoteQueueService: ConsultNoteQueueService,
    private toastService: AppToastService,
    private titleService: Title,
    private authService: AuthenticationService,
    private userPresenceService: UserPresenceService,
    private userTimingService: UserTimingService
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    this.patientAttachmentId = this.route.snapshot.paramMap.get('id');
    this.canAdmin = this.authService.isCaptureAdminUser;
    this.loadPatientAttachment(this.patientAttachmentId);

    this.patientAttachmentChangedSubscription = this.patientAttachmentsService.patientAttachmentChanged.subscribe(
      ({ patientAttachment }) => {
        this.startTimeTracking(patientAttachment);

        if (patientAttachment.id === this.patientAttachment.id) {
          this.handlePatientAttachmentChange(patientAttachment);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.patientAttachmentChangedSubscription) {
      this.patientAttachmentChangedSubscription.unsubscribe();
    }

    this.stopTimeTracking();
    this.userPresenceService.leaveAndUnsubscribe("consultNote", this.patientAttachmentId);
  }

  onSectionClick(sectionId: string) {
    this.currentSection = sectionId;

    const mainNavBarRect = document.getElementById('mainNavbar').getBoundingClientRect();
    const consultNoteTopRect = document.getElementById('consultNoteTop').getBoundingClientRect();
    const sectionElement = document.getElementById(sectionId);

    const offsetTop = consultNoteTopRect.height - mainNavBarRect.height;
    const position = sectionElement.offsetTop - offsetTop;

    document.documentElement.scrollTo({ top: position, behavior: 'smooth' });
  }

  onResized() {
    const consultNoteTop = document.getElementById('consultNoteTop');
    const consultNoteTopRect = consultNoteTop.getBoundingClientRect();

    const height = `calc(100vh - ${Math.max(consultNoteTopRect.bottom, 0)}px)`;
    const autoResizeElements = document.querySelectorAll('.auto-resize-height, #pdfFileViewer');

    autoResizeElements.forEach((autoResizeElement: HTMLElement) => {
      autoResizeElement.style.height = height;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.debouncedWindowScroll();
  }

  onMessageClose() {
    this.submitConsultNoteResult = null;
  }

  onSubmitClick($event: MouseEvent) {
    $event.preventDefault();

    this.toastService.show(this.consultNoteProcessingRef, { 'cssClass': 'w-100 bg-warning-subtle', 'keepOpen': true });

    this.patientAttachmentsService.submit(this.patientAttachment).subscribe(result => {
      this.processSubmitConsultNoteResult(result);
    });

    window.scrollTo(0, 0);
  }

  private processSubmitConsultNoteResult(result: SubmitConsultNoteResult) {
    this.patientAttachment = result.patientAttachment;
    this.submitEnabled = this.isSubmitEnabled(this.patientAttachment);
    this.submitConsultNoteResult = result;

    // clear any previously displayed flash
    // messages.
    this.toastService.clear();

    this.buildSubmitConsultNoteCounts();

    const flashMessageCssClass = this.rolledUpFailedTransitionedCaptureStatusCounts.length > 0 ? 'bg-warning-subtle' : 'bg-success-subtle';
    this.toastService.show(this.consultNoteSubmitResultRef, { 'cssClass': flashMessageCssClass, 'keepOpen': true });
  }

  onCancelClick($event: MouseEvent) {
    $event.preventDefault();
    this.navigationService.navigateTo(`/capture-admin/consult-notes`);
  }

  onNextClick($event: MouseEvent) {
    $event.preventDefault();

    this.consultNoteQueueService.getNextResult(this.patientAttachment).subscribe((nextResult: NextResult) => {
      if (nextResult) {
        this.navigationService.navigateTo(`/capture-admin/consult-notes/${nextResult.id}`);
      } else {
        this.navigationService.navigateTo(`/capture-admin/consult-notes`);
      }
    });

    window.scrollTo(0, 0);
  }

  onMarkedAsInvalidChanged(pa: PatientAttachment) {
    this.patientAttachment = pa;
    this.submitEnabled = this.isSubmitEnabled(this.patientAttachment);
  }

  private loadPatientAttachment(patientAttachmentId: string): void {
    this.refreshing = true;
    this.patientAttachmentsService.get(patientAttachmentId).subscribe(
      (patientAttachment: PatientAttachment) => {
        if (!patientAttachment.standardConsultNote) {
          this.navigationService.navigateTo('404');
        } else {
          this.refreshing = false;
          this.patientAttachment = patientAttachment;
          this.submitEnabled = this.isSubmitEnabled(this.patientAttachment);
          this.setTitle();
          this.userPresenceService.subscribeAndJoin("consultNote", patientAttachmentId);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.navigationService.notFound();
        } else {
          console.error(err);
        }
      }
    );

    window.scrollTo(0, 0);
  }

  private handleWindowScroll() {
    const consultNoteTop = document.getElementById('consultNoteTop');

    if (consultNoteTop) {
      const consultNoteTopRectangle = consultNoteTop.getBoundingClientRect();

      let sections = Array.from(document.querySelectorAll('.consult-note-section'))
        .map(section => {
          const rect = section.getBoundingClientRect();
          const offset = Math.max(0, rect.bottom - consultNoteTopRectangle.bottom);
          return { id: section.id, offset };
        })
        .filter(section => section.offset > ScrollOffsetThreshold);

      sections = sortBy(sections, 'offset');

      if (sections.length > 0 && sections[0].id !== this.currentSection) {
        this.currentSection = sections[0].id;
      }
    }
  }

  private setTitle() {
    const statusOption = keyedPatientAttachmentStatusOptions[this.patientAttachment.status];
    const status = statusOption.display || '--';
    const title = `R1 340B Recovery - ${this.patientAttachment.id} | ${status}`;
    this.titleService.setTitle(title);
  }

  private handleRouteChange(paramsMap: any) {
    const params = paramsMap['params'];
    const patientAttachmentId = params && params['id'];

    if (this.navigationService.shouldReloadPage(this.patientAttachment, patientAttachmentId)) {
      this.userPresenceService.leaveAndUnsubscribe("consultNote", this.patientAttachmentId);
      this.patientAttachmentId = patientAttachmentId;
      this.loadPatientAttachment(patientAttachmentId);
    }
  }

  private handlePatientAttachmentChange(patientAttachment: PatientAttachment) {
    this.patientAttachmentsService
      .getStatus(patientAttachment.id)
      .subscribe((result: { status: string; completeForSubmit: boolean }) => {
        patientAttachment.status = result.status;
        patientAttachment.completeForSubmit = result.completeForSubmit;
        this.patientAttachment = patientAttachment;
        this.submitEnabled = this.isSubmitEnabled(this.patientAttachment);
      });
  }

  private startTimeTracking(patientAttachment: PatientAttachment) {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.track({
        page: 'consult_note_details',
        recordId: patientAttachment.id,
        status: patientAttachment.status,
      });
    }
  }

  private stopTimeTracking() {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.stop();
    }
  }

  // Start of the code to handle the submit consult note result flash message.
  // It would be nice if the toastService took a Component on show() call instead
  // of just a string or TemplateRef.

  public get prescriptionsLink() {
    return `${this.baseConsultNotePath}/prescriptions`;
  }

  public get consultNoteHistoryLink() {
    return `${this.baseConsultNotePath}/history`;
  }

  public get consultNoteQueueLink() {
    return '/capture-admin/consult-notes';
  }

  private get baseConsultNotePath(): string {
    return `/capture-admin/consult-notes/${this.patientAttachment.id}`;
  }

  public get noCapturesVerifiedOrTransitioned(): boolean {
    return this.verifiedCount === 0 &&
      this.rolledUpTransitionedCaptureStatusCounts.length === 0 &&
      this.rolledUpFailedTransitionedCaptureStatusCounts.length === 0;
  }

  public get humanizedVerifiedCount(): string {
    if (this.verifiedCount === 1) {
      return `${this.verifiedCount} capture`;
    } else {
      return `${this.verifiedCount} captures`;
    }
  }

  public displayReasonOrError(reasonOrError: string) {
    const reason = this.displayReason(reasonOrError);
    return reasonOrError !== '_NO_REASON_' ? reason || reasonOrError : null;
  }

  public displayTransitionedCounts(entry: [string, string, number]) {
    const values = [
      displayCaptureStatus(entry[0]),
      this.displayReasonOrError(entry[1]),
      entry[2]
    ];

    return values.filter(value => value !== null).join(' - ');
  }

  private displayReason(reason: string) {
    const snakeCaseReason = camelToSnakeCase(reason);
    if (keyedReasonOptions[snakeCaseReason]) {
      return keyedReasonOptions[snakeCaseReason].display;
    }
  }

  private buildSubmitConsultNoteCounts() {
    const submittedHistoryItem = this.submitConsultNoteResult.submittedPatientAttachmentHistoryItem;
    const data = submittedHistoryItem.data;

    // reset counts
    this.verifiedCount = 0;
    this.rolledUpTransitionedCaptureStatusCounts = [];
    this.rolledUpFailedTransitionedCaptureStatusCounts = [];

    if (data) {
      data.transitionedCaptureStatusCounts?.forEach(([status, reasonWithCounts]) => {
        Object.entries(reasonWithCounts).forEach(([reason, count]) => {
          if (this.isVerifiedStatus(status)) {
            this.verifiedCount += count as number;
          } else {
            this.rolledUpTransitionedCaptureStatusCounts.push([status, reason, count]);
          }
        });
      });

      data.failedTransitionedCaptureStatusCounts?.forEach(([status, reasonWithCounts]) => {
        Object.entries(reasonWithCounts).forEach(([reason, count]) => {
          this.rolledUpFailedTransitionedCaptureStatusCounts.push([status, reason, count]);
        });
      });
    }
  }

  private isVerifiedStatus(status: CaptureStatus): boolean {
    return [
      CaptureStatus.verified,
      CaptureStatus.verifiedClientPrescription,
      CaptureStatus.verifiedClientPrescriberList,
      CaptureStatus.verifiedClientHasConsultNote,
      CaptureStatus.readyToVerify
    ].includes(status);
  }

  // End submit consult note flash message code

  private isSubmitEnabled(patientAttachment: PatientAttachment): boolean {
    return patientAttachment.client.active && isConsultNoteSubmittable(patientAttachment);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MinimalProvider } from 'app/core/models/minimal-provider.model';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { ReferralSpecialty } from 'app/core/models/referral-specialty.model';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { map, uniqBy } from 'lodash-es';

@Component({
  selector: 'app-consult-note-edit-specialty',
  templateUrl: './consult-note-edit-specialty.component.html',
  styleUrl: './consult-note-edit-specialty.component.scss'
})
export class ConsultNoteEditSpecialtyComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;
  @Input() referralSpecialties: ReferralSpecialty[];

  uniqueProviders: MinimalProvider[];
  loadingProviders = true;

  constructor(
    private activeModal: NgbActiveModal,
    private patientAttachmentsService: PatientAttachmentsService
  ) {}

  ngOnInit(): void {
    this.patientAttachmentsService.getVerifyingCaptures(this.patientAttachment.id).subscribe((captures) => {
      const providers = map(captures, 'provider');
      this.uniqueProviders = uniqBy(providers, 'id');
      this.loadingProviders = false;
    })
  }

  onCancelClick(): void {
    this.activeModal.dismiss();
  }

  onSaveClick($event: Event): void {
    $event.preventDefault();

    this.patientAttachmentsService.updateSpecialty(this.patientAttachment).subscribe((pa: PatientAttachment) => {
      const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);
      modifiedPatientAttachment.selectedSpecialty = pa.selectedSpecialty;
      modifiedPatientAttachment.selectedSpecialtyAdditionalDetails = pa.selectedSpecialtyAdditionalDetails;
      modifiedPatientAttachment.selectedSpecialtyFrozen = pa.selectedSpecialtyFrozen;
      this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
      this.activeModal.close();
    });
  }
}
